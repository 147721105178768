export const useParallax = () => {
  const targetSelector = "[data-parallax]";
  const targets = document.querySelectorAll(targetSelector);

  // パララックスを付与する
  // backgroundPositionYを変更して視差を出す
  const main = () => {
    const windowHeight = window.innerHeight;
    targets.forEach((target) => {
      // ターゲットが画面の中にあるかどうか
      const targetRect = target.getBoundingClientRect();
      // ターゲットの画面内でのY座標 (ターゲットを通りすぎたらマイナス値)
      const targetTopY = targetRect.top;
      const taregtBottomY = targetTopY + targetRect.height;

      // 下限がマイナス値は通り過ぎているのでなにもしない
      if (taregtBottomY < 0) return;
      // 上限が画面の高さを超えている場合はまだ画面に入っていないのでなにもしない
      if (targetTopY > windowHeight) return;

      // 変化倍率。大きいほど大きく動く (先にこちらを固定してからオフセットを調整するとうまくいく)
      let ratio = 20;
      if (target.hasAttribute("data-parallax-ratio")) {
        ratio = Number(target.getAttribute("data-parallax-ratio") as string);
      }
      // 調整用のオフセット
      let offset = 0;
      if (target.hasAttribute("data-parallax-offset")) {
        offset = Number(target.getAttribute("data-parallax-offset") as string);
      }
      // SP用のオフセットがある場合はそれをあてる
      if (window.innerWidth < 768) {
        if (target.hasAttribute("data-parallax-offset-sp")) {
          offset = Number(target.getAttribute("data-parallax-offset-sp") as string);
        }
      }
      // 基準地
      let position = "top";
      if (target.hasAttribute("data-parallax-position")) {
        position = target.getAttribute("data-parallax-position") as string;
      }

      let diff = (targetTopY * (ratio / 100) + offset) * -1;
      const element = target as HTMLElement;
      let parameter = "";
      if (position === "bottom") {
        let symbol = "+";
        if (diff > 0) {
          symbol = "-";
          diff *= -1;
        }
        parameter = `calc(100% ${symbol} ${diff}px)`;
      } else {
        parameter = `${diff}px`;
      }
      element.style.backgroundPositionY = parameter;
    });
  };

  //
  // 初期化
  //
  if (targets) {
    window.addEventListener("scroll", main);
    window.addEventListener("resize", main);
    main();
  }
};
