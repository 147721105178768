import { Application } from "@hotwired/stimulus";
import { registerControllers } from "stimulus-vite-helpers";

export const stimulus = () => {
  const application = Application.start();
  application.debug = false;
  window.Stimulus = application;
  // import dir: effects
  const controllers = import.meta.glob("./effects/**/*_controller.ts", { eager: true });
  registerControllers(application, controllers);
};
